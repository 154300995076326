@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html {
       background-color: #1a1a1a;
}

body {
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #1a1a1a;
        text-align: left;
        overflow-x:hidden;
        height: 100% !important;
}

.other-page-title {
        font-size: 5em;
        font-weight: 500;
        line-height: 1em;
}

.everything {
        overflow: hidden;
        width: 100vw !important;
        transition: 1.5s opacity ease;
}

.text-right {
        text-align: right;
}

.text-left {
        text-align: left;
}

.text-center {
        text-align: center;
}

.left {
        float: left;
}

.right {
        float: right;
}

.particles {
        height: 100vh !important;
        position: absolute !important;
        width: 100vw !important;
        background: transparent !important;
}

.big-icon {
        background-color: #ffdead !important;
        color: #cbac7d !important;
        width: 90px !important;
        height: 90px !important;
        display: inline-flex !important;
        border: 6px double #cbac7d !important;
}

.big-icon-inner {
        font-size: 3em !important;
}

@keyframes bobble
    {
      0%   {left:40%}
      50%  {left:50%}
      100% {left:40%}
}

@keyframes bobble-reverse
    {
      0%   {left:0%}
      50%  {left:-10%}
      100% {left:0%}
}

.slanted-background {
        width: 100%;
        height: 100%;
        -ms-transform-origin: 0;
        -webkit-transform-origin: 0;
        transform-origin: 0;
        position: absolute;
        z-index: -100;
        padding-bottom: 100px;
}

.slanted-background > span {
        position: absolute;
        right: auto;
}

.slanted-background :nth-child(4), .slanted-background :nth-child(5), .slanted-background :nth-child(6),
.slanted-background :nth-child(7), .slanted-background :nth-child(8), .slanted-background :nth-child(9) {
        bottom: 0;
        border-radius: 15px 15px 15px 15px;
}
    
.slanted-background :nth-child(7) {
        border-radius: 0px 15px 15px 0px;
        width: 25%;
        height: 80px;
        margin-top: -10px;
        margin-bottom: -10px;
}

.slanted-background :nth-child(8) {
        width: 60%;
        left: 20%;
        height: 25px;
        margin-bottom: -2px;
}

.slanted-background :nth-child(9) {
        width: 100%;
        border-radius: 10px 0px 0px 10px;
        left: 75%;
        height: 40px;
        margin-top: -7px;
        margin-bottom: -7px;
}

.slanted-background :first-child, .slanted-background :nth-child(4) {
        width: 41%;
        height: 200px;
        opacity: 0.7;
        border-radius: 0px 15px 15px 0px;
        animation: bobble-reverse 12s infinite;
}

.slanted-background :first-child {
        animation: bobble-reverse 15s infinite;
}

.slanted-background :nth-child(4) {
        animation: bobble-reverse 9s infinite;
}

.slanted-background :nth-child(2) {
        animation: bobble 10s infinite;
} 
.slanted-background :nth-child(6){
        animation: bobble 13s infinite;
}

.slanted-background :nth-child(2), .slanted-background :nth-child(6){
        width: 30%;
        left: 40%;
        opacity: 0.4;
        height: 240px;
        border-radius: 15px 15px 15px 15px;
}

.slanted-background :nth-child(3), .slanted-background :nth-child(5) {
        width: 100%;
        left: 30%;
        height: 135px;
        border-radius: 15px 15px 15px 15px;
}


.techstack-inner {
        text-align: left;
        display: inline-block;
}

.techstack-icon {
        margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
        display: inline-block;
        font-size: 5em !important;
        padding-left: 15px;
        padding-right: 15px;
        line-height: .1 !important;
        color: #ffdead;
}

.icon-react {
         margin-bottom: 5px;
}

.publication > div {
        background-color: #222222;
}

.publication > div > div > h2 {
        font-weight: bold;
        color: #ffdead;
}

.publication > div > div > p {
        color: #ffdead;
        opacity: 0.75;
}

.publication > div > div > a {
        float: right;
        color: #ffdead;
}

#award-winning {
        margin-bottom: -35px;
        font-size: 5em;
}
.illustration {
        height: auto;
        max-width: 100%;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
}

#social-icons {
        opacity: 0.35;
        transition: 1s;
}

#social-icons:hover {
        opacity: 1;
}

.social-icon {
        margin-right: 10px;
        border-radius: 25px;
        height: 40px;
        max-width: 100%;
        transition: 0.02s;
}

#social-icons-footer > a {
        padding: 13px;
        opacity: 0.65;
        width: 1.8em;
        border-radius: 50px;
        font-size: 1.8em;
        transition: 0.4s ease;
        margin-left: 5px;
}

#social-icons-footer > a:hover {
        opacity: 1;
}

#social-icons-footer svg {
        display: block;
        margin: auto;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.behind-sbg {
        width: 100vw;
        background-color: #131313;
        height: 100%;
        position: absolute;
        z-index: -100;
}

header {
        position: relative;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.main-content {
        position: relative;
        padding-bottom: 75px;
}

.relative {
        position: relative;
}

.white-text {
        color: #ffdead;
}

#tagline {
        padding: 30px;
        font-size: 16.5px;
        color: white;
        margin-top: 8px;
}

#navbar {
        padding-top: 15px;
}

.logo {
        width: 7em;
}

#root {
        width: 100vw;
        height: 100vh;
        position: absolute;
        left:0;
        top:0;
}

#content {
        z-index: 10;
        min-height: 80vh;
        padding-bottom: 200px;
}

#blog-entries-box a {
        text-decoration: none;
}

#blog-entries-box h3:hover, #back-to-blogs:hover {
        opacity: 0.4;
}

#blog-entries-box h3, #back-to-blogs {
        text-decoration: none;
        font-weight: bold;
        display: block;
        font-size: 1em;
        transition: 0.25s ease;
        line-height: 1em;     
        text-transform: uppercase;                
}

#back-to-blogs {
        margin-top: 70px;
        font-size: 1.3em;
        padding: 10px;
}

#blog-entries-box li {
        font-size: 1.3em;
        line-height: 0.5em;
        font-weight: bold;
}

.work-box {
        box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.4);
        margin-top: 50px !important;
        background-color: #eeeeee;
        min-height: 400px;
}

.work-box-inner {
        padding: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
        transition: 0.3s;
        overflow: hidden;
}

.darker-image {
        opacity: 0.5;
        transition: 0.7s ease;
        max-width: 100%;
}

.work-box:hover > .darker-bg > .darker-image {
        opacity: 1;
        cursor: pointer;
        transition: 0.7s ease;
}

.work-box:hover {
        cursor: pointer;
}

.work-box-image {
        width: 100%;
}

.work-box-image-parent {
        width: 100%;
}

.move-me-down {
        line-height: 0.8em !important;
        width: 100%;
        margin-top: 50px !important;
}

.move-me-down > p {
        display: inline-block !important;
        line-height: 0.8em !important;
}

.move-me-down > a, .move-me-down > a:active {
        display: inline-block;
        padding-right: 15px;
        padding-left: 15px;
}

.work-image {
        width: 100%;
        height: 100vh;
}

.work-info {
        width: 80%;
        background-color: #eeeeee;
}

.work-title {
        line-height: 1em;
        font-size: 2em;
        font-weight: 500;
}
.work-subtitle {
        margin-top: -25px;
        line-height: 1em;
        font-size: 1.15em;
        font-weight: 400;
        opacity: 0.85;
}
.work-paragraph {
        line-height: 1.25em;
        opacity: 0.75;
}

.work-button {
        float: right;
}

Button {

        transition: 0.25s ease !important;
}


.eighty {
        width: 75vw !important;
        margin-left: auto !important;
        margin-right: auto !important;
}

.light > .nav-drawer, .light > .menu  {
        background-color: #131313;
}

.dark > .nav-drawer, .dark > .menu {
        background-color: white;
}

.dark > .ham {
        background-color: #131313;
}

.light > .ham {
        background-color: white;
}

.nav-drawer {
        width: 45vw !important;
        padding: 65px;
        overflow: hidden !important;
        height: 100vh !important;
}
.block {
        z-index: -200;
        padding-bottom: 200px;
}

.block-no-margin {
        z-index: -200;
        padding-bottom: 75px;
}

.padding-bottom {
        padding-bottom: 50px;
}

.margin-auto {
        margin-right: auto;
}

@media only screen and (max-width: 600px) {
        .nav-drawer {
                width: 100vw !important;
        }
        .eighty {
                width: 95vw !important;
                margin-left: auto !important;
                margin-right: auto !important;
        }
        .block {
                padding-bottom: 100px;
        }
        .footer-txt a {
                line-height: 1.5em;
        }
}

.menu {
        background-color: #131313;
        height: 100vh;
        margin-top: -30px !important;
        width: 80vw !important;
}

.menu-list {
        text-align: left;
        margin-top: 10px !important;
        color: white;
}

.menu-item:hover {
        transition: 0.5s ease;
        opacity: 0.8;
}

.menu-list > .menu-item {
        display: block;
        transition: 0.5s ease;
        font-size: 2.5em;
        text-decoration: none;
        font-weight: 500;
}

.menu-item {
        display: inline-block;
        transition: 0.5s ease;
        font-size: 2.5em;
        text-decoration: none;
        font-weight: 500;
}

.menu-list-horizontal {
        text-align: right;
        width: 100%; 
        margin-top: 20px;
        position: relative;
        border: 1px solid transparent;
}

.menu-list-horizontal > .menu-item {
        display: inline-block;
        font-size: 1.25em;
        text-decoration: none;
        font-weight: 500;
        letter-spacing: -.5px;
        padding: 20px;
        height: 100%;
}

.dark > .menu-item, .menu-title-dark {
        color: #131313;
}

.dark > .menu-item:hover {
        color: #696969;
}

.light > .menu-item, .menu-title-light {
        color: #ffdead;
}

.light > .menu-item:hover {
        color: white;
}

.menu-list-horizontal > .menu-item {
        border-bottom: 10px solid transparent;
}

.menu-item:hover > a {
        transition: 0.5s ease;
}

.top-of-menu {
        width: 100%;
        font-size: 0.8em;
}

.exit {
        transition: 0.5s ease !important;
}
.exit:hover {
        cursor: pointer;
        letter-spacing: 2px;
        transition: 0.5s ease;
}


.bold {
        font-weight: 700 !important;
}

.hamburger {
    display: inline-block;
    cursor: pointer;
    float: right;
    padding: 40px;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 2px;
    margin: 6px 0;
    transition: 0.4s;
}



.mini-title {
  opacity: 0.85;
  letter-spacing: 4px;
  font-size: 1rem;
  font-family: 'Helvetica', sans-serif;
}


.other-page-subtitle {
        opacity: 0.85;
        font-weight: 300;
}

.paragraph {
  color: black;
  opacity: 0.65;
  margin-top: -50px;
  font-size: 20px;
  line-height: 1.5em;
}

.extended {
  margin-top: -65px;
}

.small-p {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
}

.pager-title {
        letter-spacing: -2px;
        line-height: 1.12;
        color: black;
        font-size: 5.5em;
        font-weight: 700;
}

.sub-title {
        margin-top: -50px;
        font-size: 25px;
        line-height: 1.5em; 
        font-weight: normal;
}

.cover-letter span {
        font-size: 1.2em;
}

.cover-letter .sub-title {
        margin-bottom: 20px;
        font-weight: bold;
}

.cover-letter p {
        letter-spacing: 0.25px;
        line-height: 2.25em;
}

.cover-letter {
        padding-bottom: 150px;
}

.cover-letter li {
        opacity: 0.7;
        padding: 15px;
}

.cover-letter a {
        color: #ffdead;
}

.cover-letter .cv-button {
        float: right;
}

.blog-pager-title {
        letter-spacing: -2px;
        line-height: 1.12;
        color: black;
        font-size: 75px;
        font-weight: 700;
        padding-bottom: 10px;
}

.blog-sub-title {
        margin-top: -80px;
        font-size: 25px;
        line-height: 1.5em; 
        font-weight: normal;
}

.blog-date {
        font-style: italic;
}

#blog-entry-box p {
        font-size: 1.15em;
}

.blogger a {
        color: black;
}

.flat-reverse {
        transform: scale(-1, 1);
}

#profile-image {
        border-radius: 50%;
        width: 12em;
}

.title-container {
        margin-top: -40px;
}

#contact-box input, #contact-box textarea {
        font-size: 24px;
        line-height: 24px;
}

#contact-box form > div {
        width: 85%;
}

#contact-box form > div > label {
        color: #ffdead;
}

#contact-box form > div > div::before, #contact-box form > div > div::after  {
        border-color: #ffdead;
}

#contact-box input, #contact-box textarea {
        color: white;
}

#about-box {
        margin-top: 5px;
}


#about-box:first-child {
        padding-top: 50px;
}

#about-box:last-child {
        padding-bottom: 100px;
}

#about-box > div > h3 {
        font-size: 23px;
        font-weight: bold;
        text-transform: uppercase;
}

#about-box > div > p {
        margin-top: -10px;
}

#about-box > div > ul > li, #about-box > div > p {
        font-size: 18px;
        opacity: 0.85;
}

#about-box > div > ul > li.inactive, #about-box > div > p.inactive {
        opacity: 0.5;
        font-size: 14px;
        font-style: italic;
}

@media only screen and (max-width: 600px) {
        .pager-title {
                letter-spacing: -2px;
                line-height: 1.12;
                color: black;
                font-size: 50px;
                font-weight: 700;
        }

        .blog-pager-title {
                letter-spacing: -2px;
                line-height: 1.12;
                color: black;
                font-size: 50px;
                font-weight: 700;
                padding-bottom: 30px;
        }

        .sub-title {
                margin-top: -40px;
                font-size: 25px;
                line-height: 1.5em; 
                font-weight: normal;
        }

        .paragraph {
                color: black;
                opacity: 0.65;
                margin-top: -40px;
                font-size: 20px;
                line-height: 1.5em;
        }
}

#other-part-of-statement {
        font-size: 4em;
        line-height: 0.9;
        color: #555555;
}

#statement {
        color: black;
        font-weight: 900;
        font-size: 3em;
}

.image-container {
        height: 60vh;
        margin-top: 70px !important;
}

.image-sub {
        opacity: 0.3;
}

.all-portfolio {
        padding-bottom: 120px;
}

.work-box-inner-image {
        padding: 0 !important;
        transition: 0.5s;
        background-color: black;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
        box-shadow: inset 10px 17px 254px 54px rgba(0,0,0,0.68);
        opacity: 0.8;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
}

.image-title {
        font-size: 1em; 
        width: 100%;
        z-index: 1;
        font-weight: 600;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
}

      .loader:empty {
        position: absolute;
        background-image: url('img/loader.gif');
        background-repeat: no-repeat;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%,-50%) !important;
        width: 200px !important;
        height: 200px !important;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

.strikethrough {
        text-decoration: line-through !important;
}

.work-box-inner-image:hover {
        box-shadow: none;
        opacity:1;
        transition: 0.5s;
        cursor: pointer;
}

@media only screen and (max-width: 1200px) {
        #statement {
                font-size: 3em;
        }
        #other-part-of-statement {
                font-size: 3em;
                line-height: 0.9;
                color: #666666;
        }

        .image-container {
                height: 40vh;
        }
}

@media only screen and (max-width: 800px) {
        #statement {
                font-size:2.4em;
        }
        #other-part-of-statement {
                font-size: 1.8em;
                line-height: 0.9;
                color: #666666;
        }
        .image-container {
                height: 25vh;
        }
}

.text-center {
        text-align: center;
}

.cream-chip {
        margin-top: 10px !important;
        background-color: #ffdead !important;
        font-weight: 400;
}

#publications-title {
        margin-top: 100px;
}

.text-right-footer {
        text-align: right;
}

.text-left-footer {
        text-align: left;
}

.footer-txt {
        width: 100%;
}

@media only screen and (max-width: 1000px) {
        .text-left-footer, .text-right-footer {
                text-align: center;
        }
}

.cream-button {
        margin-right: 10px !important;
        margin-top: 10px !important;
        color: #131313 !important;
        background-color: #ffdead !important;
        font-weight: 400;
        transition: 0.25s ease !important;
}

.cream-button-reverse {
        margin-right: 10px !important;
        margin-top: 10px !important;
        color: #ffdead !important;
        background-color: #222222 !important;
        font-weight: 400;
        transition: 0.25s ease !important;
}

.cream-button-hollow {
        margin-right: 10px !important;
        margin-top: 10px !important;
        color: #ffdead !important;
        background-color: transparent !important;
        font-weight: 400;
        border: 1px solid #ffdead !important;
        transition: 0.25s ease !important;
}

Button:hover {
        letter-spacing: 1px;
        transition: 0.25s ease;
}

.down {
        margin-top: 75px !important;
}

.padding-down {
        padding-top: 85px !important;
}

.add-bottom-padding {
        padding-bottom: 45px !important;
}

hr {
   display: block;
   height: 1px;
   border: 0;
   border-top: 1px solid white;
   margin: 1em 0;
   padding: 0;
}

#circle {
    background: #f00;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.first-title {
        font-size: 45px;
        line-height: 45px;
        font-weight: 700;
        opacity: 0.9;
        letter-spacing: -.9px;
}

.xl-title {
        font-size: 60px;
        line-height: 60px;
        font-weight: 700;
        opacity: 0.9;
        letter-spacing: -.9px;
}

.jumbo-title {
        font-size: 2.8rem;
        font-weight: 700;
        line-height: 1em;
        opacity: 0.9;
}

.second-title {
        margin-top: -15px;
        opacity: 0.85;
        font-weight: 300;
}

.SlideIn-appear {
  transform: translateY(-35px);
  opacity: 0;
}
.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s ease;
}
.SlideIn-enter {
    opacity: 0;
    transform: translateY(-15px);
}
.SlideIn-enter.SlideIn-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1s ease;
}
.SlideIn-leave {
    opacity: 1.0;
    transform: translateY(0);
}
.SlideIn-leave.SlideIn-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateY(60px);
    transition: all 0.2s ease;
}

.project-box {
    padding: 20px 30px 20px 30px;
    background-color: #222222;
    color: #ffdead;
    transition: 2s ease;
    margin-top: 5px;
}

.project-box-link {
    text-decoration: none;
}

.project-box .second-title {
    line-height: 0.2em;
    font-size: 1.25em;
}

.project-box:hover {
    cursor: pointer;
    border-left: 30px solid #ffdead;
}

.project-box .jumbo-title {
    margin-top: 10px;
}