@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
html {
       background-color: #1a1a1a;
}

body {
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #1a1a1a;
        text-align: left;
        overflow-x:hidden;
        height: 100% !important;
}

.other-page-title {
        font-size: 5em;
        font-weight: 500;
        line-height: 1em;
}

.everything {
        overflow: hidden;
        width: 100vw !important;
        transition: 1.5s opacity ease;
}

.text-right {
        text-align: right;
}

.text-left {
        text-align: left;
}

.text-center {
        text-align: center;
}

.left {
        float: left;
}

.right {
        float: right;
}

.particles {
        height: 100vh !important;
        position: absolute !important;
        width: 100vw !important;
        background: transparent !important;
}

.big-icon {
        background-color: #ffdead !important;
        color: #cbac7d !important;
        width: 90px !important;
        height: 90px !important;
        display: inline-flex !important;
        border: 6px double #cbac7d !important;
}

.big-icon-inner {
        font-size: 3em !important;
}

@-webkit-keyframes bobble
    {
      0%   {left:40%}
      50%  {left:50%}
      100% {left:40%}
}

@keyframes bobble
    {
      0%   {left:40%}
      50%  {left:50%}
      100% {left:40%}
}

@-webkit-keyframes bobble-reverse
    {
      0%   {left:0%}
      50%  {left:-10%}
      100% {left:0%}
}

@keyframes bobble-reverse
    {
      0%   {left:0%}
      50%  {left:-10%}
      100% {left:0%}
}

.slanted-background {
        width: 100%;
        height: 100%;
        transform-origin: 0;
        position: absolute;
        z-index: -100;
        padding-bottom: 100px;
}

.slanted-background > span {
        position: absolute;
        right: auto;
}

.slanted-background :nth-child(4), .slanted-background :nth-child(5), .slanted-background :nth-child(6),
.slanted-background :nth-child(7), .slanted-background :nth-child(8), .slanted-background :nth-child(9) {
        bottom: 0;
        border-radius: 15px 15px 15px 15px;
}
    
.slanted-background :nth-child(7) {
        border-radius: 0px 15px 15px 0px;
        width: 25%;
        height: 80px;
        margin-top: -10px;
        margin-bottom: -10px;
}

.slanted-background :nth-child(8) {
        width: 60%;
        left: 20%;
        height: 25px;
        margin-bottom: -2px;
}

.slanted-background :nth-child(9) {
        width: 100%;
        border-radius: 10px 0px 0px 10px;
        left: 75%;
        height: 40px;
        margin-top: -7px;
        margin-bottom: -7px;
}

.slanted-background :first-child, .slanted-background :nth-child(4) {
        width: 41%;
        height: 200px;
        opacity: 0.7;
        border-radius: 0px 15px 15px 0px;
        -webkit-animation: bobble-reverse 12s infinite;
                animation: bobble-reverse 12s infinite;
}

.slanted-background :first-child {
        -webkit-animation: bobble-reverse 15s infinite;
                animation: bobble-reverse 15s infinite;
}

.slanted-background :nth-child(4) {
        -webkit-animation: bobble-reverse 9s infinite;
                animation: bobble-reverse 9s infinite;
}

.slanted-background :nth-child(2) {
        -webkit-animation: bobble 10s infinite;
                animation: bobble 10s infinite;
} 
.slanted-background :nth-child(6){
        -webkit-animation: bobble 13s infinite;
                animation: bobble 13s infinite;
}

.slanted-background :nth-child(2), .slanted-background :nth-child(6){
        width: 30%;
        left: 40%;
        opacity: 0.4;
        height: 240px;
        border-radius: 15px 15px 15px 15px;
}

.slanted-background :nth-child(3), .slanted-background :nth-child(5) {
        width: 100%;
        left: 30%;
        height: 135px;
        border-radius: 15px 15px 15px 15px;
}


.techstack-inner {
        text-align: left;
        display: inline-block;
}

.techstack-icon {
        -webkit-margin-before: 0.67em;
                margin-block-start: 0.67em;
    -webkit-margin-after: 0.67em;
            margin-block-end: 0.67em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
        display: inline-block;
        font-size: 5em !important;
        padding-left: 15px;
        padding-right: 15px;
        line-height: .1 !important;
        color: #ffdead;
}

.icon-react {
         margin-bottom: 5px;
}

.publication > div {
        background-color: #222222;
}

.publication > div > div > h2 {
        font-weight: bold;
        color: #ffdead;
}

.publication > div > div > p {
        color: #ffdead;
        opacity: 0.75;
}

.publication > div > div > a {
        float: right;
        color: #ffdead;
}

#award-winning {
        margin-bottom: -35px;
        font-size: 5em;
}
.illustration {
        height: auto;
        max-width: 100%;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
}

#social-icons {
        opacity: 0.35;
        transition: 1s;
}

#social-icons:hover {
        opacity: 1;
}

.social-icon {
        margin-right: 10px;
        border-radius: 25px;
        height: 40px;
        max-width: 100%;
        transition: 0.02s;
}

#social-icons-footer > a {
        padding: 13px;
        opacity: 0.65;
        width: 1.8em;
        border-radius: 50px;
        font-size: 1.8em;
        transition: 0.4s ease;
        margin-left: 5px;
}

#social-icons-footer > a:hover {
        opacity: 1;
}

#social-icons-footer svg {
        display: block;
        margin: auto;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.behind-sbg {
        width: 100vw;
        background-color: #131313;
        height: 100%;
        position: absolute;
        z-index: -100;
}

header {
        position: relative;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.main-content {
        position: relative;
        padding-bottom: 75px;
}

.relative {
        position: relative;
}

.white-text {
        color: #ffdead;
}

#tagline {
        padding: 30px;
        font-size: 16.5px;
        color: white;
        margin-top: 8px;
}

#navbar {
        padding-top: 15px;
}

.logo {
        width: 7em;
}

#root {
        width: 100vw;
        height: 100vh;
        position: absolute;
        left:0;
        top:0;
}

#content {
        z-index: 10;
        min-height: 80vh;
        padding-bottom: 200px;
}

#blog-entries-box a {
        text-decoration: none;
}

#blog-entries-box h3:hover, #back-to-blogs:hover {
        opacity: 0.4;
}

#blog-entries-box h3, #back-to-blogs {
        text-decoration: none;
        font-weight: bold;
        display: block;
        font-size: 1em;
        transition: 0.25s ease;
        line-height: 1em;     
        text-transform: uppercase;                
}

#back-to-blogs {
        margin-top: 70px;
        font-size: 1.3em;
        padding: 10px;
}

#blog-entries-box li {
        font-size: 1.3em;
        line-height: 0.5em;
        font-weight: bold;
}

.work-box {
        box-shadow: 10px 10px 29px 0px rgba(0,0,0,0.4);
        margin-top: 50px !important;
        background-color: #eeeeee;
        min-height: 400px;
}

.work-box-inner {
        padding: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
        transition: 0.3s;
        overflow: hidden;
}

.darker-image {
        opacity: 0.5;
        transition: 0.7s ease;
        max-width: 100%;
}

.work-box:hover > .darker-bg > .darker-image {
        opacity: 1;
        cursor: pointer;
        transition: 0.7s ease;
}

.work-box:hover {
        cursor: pointer;
}

.work-box-image {
        width: 100%;
}

.work-box-image-parent {
        width: 100%;
}

.move-me-down {
        line-height: 0.8em !important;
        width: 100%;
        margin-top: 50px !important;
}

.move-me-down > p {
        display: inline-block !important;
        line-height: 0.8em !important;
}

.move-me-down > a, .move-me-down > a:active {
        display: inline-block;
        padding-right: 15px;
        padding-left: 15px;
}

.work-image {
        width: 100%;
        height: 100vh;
}

.work-info {
        width: 80%;
        background-color: #eeeeee;
}

.work-title {
        line-height: 1em;
        font-size: 2em;
        font-weight: 500;
}
.work-subtitle {
        margin-top: -25px;
        line-height: 1em;
        font-size: 1.15em;
        font-weight: 400;
        opacity: 0.85;
}
.work-paragraph {
        line-height: 1.25em;
        opacity: 0.75;
}

.work-button {
        float: right;
}

Button {

        transition: 0.25s ease !important;
}


.eighty {
        width: 75vw !important;
        margin-left: auto !important;
        margin-right: auto !important;
}

.light > .nav-drawer, .light > .menu  {
        background-color: #131313;
}

.dark > .nav-drawer, .dark > .menu {
        background-color: white;
}

.dark > .ham {
        background-color: #131313;
}

.light > .ham {
        background-color: white;
}

.nav-drawer {
        width: 45vw !important;
        padding: 65px;
        overflow: hidden !important;
        height: 100vh !important;
}
.block {
        z-index: -200;
        padding-bottom: 200px;
}

.block-no-margin {
        z-index: -200;
        padding-bottom: 75px;
}

.padding-bottom {
        padding-bottom: 50px;
}

.margin-auto {
        margin-right: auto;
}

@media only screen and (max-width: 600px) {
        .nav-drawer {
                width: 100vw !important;
        }
        .eighty {
                width: 95vw !important;
                margin-left: auto !important;
                margin-right: auto !important;
        }
        .block {
                padding-bottom: 100px;
        }
        .footer-txt a {
                line-height: 1.5em;
        }
}

.menu {
        background-color: #131313;
        height: 100vh;
        margin-top: -30px !important;
        width: 80vw !important;
}

.menu-list {
        text-align: left;
        margin-top: 10px !important;
        color: white;
}

.menu-item:hover {
        transition: 0.5s ease;
        opacity: 0.8;
}

.menu-list > .menu-item {
        display: block;
        transition: 0.5s ease;
        font-size: 2.5em;
        text-decoration: none;
        font-weight: 500;
}

.menu-item {
        display: inline-block;
        transition: 0.5s ease;
        font-size: 2.5em;
        text-decoration: none;
        font-weight: 500;
}

.menu-list-horizontal {
        text-align: right;
        width: 100%; 
        margin-top: 20px;
        position: relative;
        border: 1px solid transparent;
}

.menu-list-horizontal > .menu-item {
        display: inline-block;
        font-size: 1.25em;
        text-decoration: none;
        font-weight: 500;
        letter-spacing: -.5px;
        padding: 20px;
        height: 100%;
}

.dark > .menu-item, .menu-title-dark {
        color: #131313;
}

.dark > .menu-item:hover {
        color: #696969;
}

.light > .menu-item, .menu-title-light {
        color: #ffdead;
}

.light > .menu-item:hover {
        color: white;
}

.menu-list-horizontal > .menu-item {
        border-bottom: 10px solid transparent;
}

.menu-item:hover > a {
        transition: 0.5s ease;
}

.top-of-menu {
        width: 100%;
        font-size: 0.8em;
}

.exit {
        transition: 0.5s ease !important;
}
.exit:hover {
        cursor: pointer;
        letter-spacing: 2px;
        transition: 0.5s ease;
}


.bold {
        font-weight: 700 !important;
}

.hamburger {
    display: inline-block;
    cursor: pointer;
    float: right;
    padding: 40px;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 2px;
    margin: 6px 0;
    transition: 0.4s;
}



.mini-title {
  opacity: 0.85;
  letter-spacing: 4px;
  font-size: 1rem;
  font-family: 'Helvetica', sans-serif;
}


.other-page-subtitle {
        opacity: 0.85;
        font-weight: 300;
}

.paragraph {
  color: black;
  opacity: 0.65;
  margin-top: -50px;
  font-size: 20px;
  line-height: 1.5em;
}

.extended {
  margin-top: -65px;
}

.small-p {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
}

.pager-title {
        letter-spacing: -2px;
        line-height: 1.12;
        color: black;
        font-size: 5.5em;
        font-weight: 700;
}

.sub-title {
        margin-top: -50px;
        font-size: 25px;
        line-height: 1.5em; 
        font-weight: normal;
}

.cover-letter span {
        font-size: 1.2em;
}

.cover-letter .sub-title {
        margin-bottom: 20px;
        font-weight: bold;
}

.cover-letter p {
        letter-spacing: 0.25px;
        line-height: 2.25em;
}

.cover-letter {
        padding-bottom: 150px;
}

.cover-letter li {
        opacity: 0.7;
        padding: 15px;
}

.cover-letter a {
        color: #ffdead;
}

.cover-letter .cv-button {
        float: right;
}

.blog-pager-title {
        letter-spacing: -2px;
        line-height: 1.12;
        color: black;
        font-size: 75px;
        font-weight: 700;
        padding-bottom: 10px;
}

.blog-sub-title {
        margin-top: -80px;
        font-size: 25px;
        line-height: 1.5em; 
        font-weight: normal;
}

.blog-date {
        font-style: italic;
}

#blog-entry-box p {
        font-size: 1.15em;
}

.blogger a {
        color: black;
}

.flat-reverse {
        transform: scale(-1, 1);
}

#profile-image {
        border-radius: 50%;
        width: 12em;
}

.title-container {
        margin-top: -40px;
}

#contact-box input, #contact-box textarea {
        font-size: 24px;
        line-height: 24px;
}

#contact-box form > div {
        width: 85%;
}

#contact-box form > div > label {
        color: #ffdead;
}

#contact-box form > div > div::before, #contact-box form > div > div::after  {
        border-color: #ffdead;
}

#contact-box input, #contact-box textarea {
        color: white;
}

#about-box {
        margin-top: 5px;
}


#about-box:first-child {
        padding-top: 50px;
}

#about-box:last-child {
        padding-bottom: 100px;
}

#about-box > div > h3 {
        font-size: 23px;
        font-weight: bold;
        text-transform: uppercase;
}

#about-box > div > p {
        margin-top: -10px;
}

#about-box > div > ul > li, #about-box > div > p {
        font-size: 18px;
        opacity: 0.85;
}

#about-box > div > ul > li.inactive, #about-box > div > p.inactive {
        opacity: 0.5;
        font-size: 14px;
        font-style: italic;
}

@media only screen and (max-width: 600px) {
        .pager-title {
                letter-spacing: -2px;
                line-height: 1.12;
                color: black;
                font-size: 50px;
                font-weight: 700;
        }

        .blog-pager-title {
                letter-spacing: -2px;
                line-height: 1.12;
                color: black;
                font-size: 50px;
                font-weight: 700;
                padding-bottom: 30px;
        }

        .sub-title {
                margin-top: -40px;
                font-size: 25px;
                line-height: 1.5em; 
                font-weight: normal;
        }

        .paragraph {
                color: black;
                opacity: 0.65;
                margin-top: -40px;
                font-size: 20px;
                line-height: 1.5em;
        }
}

#other-part-of-statement {
        font-size: 4em;
        line-height: 0.9;
        color: #555555;
}

#statement {
        color: black;
        font-weight: 900;
        font-size: 3em;
}

.image-container {
        height: 60vh;
        margin-top: 70px !important;
}

.image-sub {
        opacity: 0.3;
}

.all-portfolio {
        padding-bottom: 120px;
}

.work-box-inner-image {
        padding: 0 !important;
        transition: 0.5s;
        background-color: black;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
        box-shadow: inset 10px 17px 254px 54px rgba(0,0,0,0.68);
        opacity: 0.8;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
}

.image-title {
        font-size: 1em; 
        width: 100%;
        z-index: 1;
        font-weight: 600;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
}

      .loader:empty {
        position: absolute;
        background-image: url(/static/media/loader.72d48a4c.gif);
        background-repeat: no-repeat;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%,-50%) !important;
        width: 200px !important;
        height: 200px !important;
    }

    @-webkit-keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

.strikethrough {
        text-decoration: line-through !important;
}

.work-box-inner-image:hover {
        box-shadow: none;
        opacity:1;
        transition: 0.5s;
        cursor: pointer;
}

@media only screen and (max-width: 1200px) {
        #statement {
                font-size: 3em;
        }
        #other-part-of-statement {
                font-size: 3em;
                line-height: 0.9;
                color: #666666;
        }

        .image-container {
                height: 40vh;
        }
}

@media only screen and (max-width: 800px) {
        #statement {
                font-size:2.4em;
        }
        #other-part-of-statement {
                font-size: 1.8em;
                line-height: 0.9;
                color: #666666;
        }
        .image-container {
                height: 25vh;
        }
}

.text-center {
        text-align: center;
}

.cream-chip {
        margin-top: 10px !important;
        background-color: #ffdead !important;
        font-weight: 400;
}

#publications-title {
        margin-top: 100px;
}

.text-right-footer {
        text-align: right;
}

.text-left-footer {
        text-align: left;
}

.footer-txt {
        width: 100%;
}

@media only screen and (max-width: 1000px) {
        .text-left-footer, .text-right-footer {
                text-align: center;
        }
}

.cream-button {
        margin-right: 10px !important;
        margin-top: 10px !important;
        color: #131313 !important;
        background-color: #ffdead !important;
        font-weight: 400;
        transition: 0.25s ease !important;
}

.cream-button-reverse {
        margin-right: 10px !important;
        margin-top: 10px !important;
        color: #ffdead !important;
        background-color: #222222 !important;
        font-weight: 400;
        transition: 0.25s ease !important;
}

.cream-button-hollow {
        margin-right: 10px !important;
        margin-top: 10px !important;
        color: #ffdead !important;
        background-color: transparent !important;
        font-weight: 400;
        border: 1px solid #ffdead !important;
        transition: 0.25s ease !important;
}

Button:hover {
        letter-spacing: 1px;
        transition: 0.25s ease;
}

.down {
        margin-top: 75px !important;
}

.padding-down {
        padding-top: 85px !important;
}

.add-bottom-padding {
        padding-bottom: 45px !important;
}

hr {
   display: block;
   height: 1px;
   border: 0;
   border-top: 1px solid white;
   margin: 1em 0;
   padding: 0;
}

#circle {
    background: #f00;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.first-title {
        font-size: 45px;
        line-height: 45px;
        font-weight: 700;
        opacity: 0.9;
        letter-spacing: -.9px;
}

.xl-title {
        font-size: 60px;
        line-height: 60px;
        font-weight: 700;
        opacity: 0.9;
        letter-spacing: -.9px;
}

.jumbo-title {
        font-size: 2.8rem;
        font-weight: 700;
        line-height: 1em;
        opacity: 0.9;
}

.second-title {
        margin-top: -15px;
        opacity: 0.85;
        font-weight: 300;
}

.SlideIn-appear {
  transform: translateY(-35px);
  opacity: 0;
}
.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s ease;
}
.SlideIn-enter {
    opacity: 0;
    transform: translateY(-15px);
}
.SlideIn-enter.SlideIn-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1s ease;
}
.SlideIn-leave {
    opacity: 1.0;
    transform: translateY(0);
}
.SlideIn-leave.SlideIn-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateY(60px);
    transition: all 0.2s ease;
}

.project-box {
    padding: 20px 30px 20px 30px;
    background-color: #222222;
    color: #ffdead;
    transition: 2s ease;
    margin-top: 5px;
}

.project-box-link {
    text-decoration: none;
}

.project-box .second-title {
    line-height: 0.2em;
    font-size: 1.25em;
}

.project-box:hover {
    cursor: pointer;
    border-left: 30px solid #ffdead;
}

.project-box .jumbo-title {
    margin-top: 10px;
}
@font-face {
	font-family: 'devicon';
	src:url(/static/media/devicon.681fa39a.eot);
	src:url(/static/media/devicon.681fa39a.eot?#iefix-hdf3wh) format('embedded-opentype'),
		url(/static/media/devicon.0f964a72.woff) format('woff'),
		url(/static/media/devicon.29f80b00.ttf) format('truetype'),
		url(/static/media/devicon.74e6e6c3.svg#devicon) format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="devicon-"], [class*=" devicon-"] {
	font-family: 'devicon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.devicon-sketch-line-wordmark:before {
  content: "\E94C";
}
.devicon-sketch-line:before {
  content: "\E94D";
}
.devicon-npm-original-wordmark:before {
  content: "\E952";
}
.devicon-ionic-original-wordmark:before {
  content: "\E953";
}
.devicon-ionic-original:before {
  content: "\E954";
}
.devicon-ember-original-wordmark:before {
  content: "\E955";
}
.devicon-electron-original-wordmark:before {
  content: "\E956";
}
.devicon-electron-original:before {
  content: "\E957";
}
.devicon-vagrant-plain-wordmark:before {
  content: "\E94E";
}
.devicon-vagrant-plain:before {
  content: "\E94F";
}
.devicon-yarn-plain-wordmark:before {
  content: "\E950";
}
.devicon-yarn-plain:before {
  content: "\E951";
}
.devicon-handlebars-plain-wordmark:before {
  content: "\E94A";
}
.devicon-handlebars-plain:before {
  content: "\E94B";
}
.devicon-couchdb-plain-wordmark:before {
  content: "\E948";
}
.devicon-couchdb-plain:before {
  content: "\E949";
}
.devicon-behance-plain-wordmark:before {
  content: "\E943";
}
.devicon-behance-plain:before {
  content: "\E945";
}
.devicon-linkedin-plain-wordmark:before {
  content: "\E946";
}
.devicon-linkedin-plain:before {
  content: "\E947";
}
.devicon-ceylon-plain-wordmark:before {
  content: "\E943";
}
.devicon-ceylon-plain:before {
  content: "\E944";
}
.devicon-elm-plain-wordmark:before {
  content: "\E941";
}
.devicon-elm-plain:before {
  content: "\E942";
}
.devicon-cakephp-plain-wordmark:before {
  content: "\E93F";
}
.devicon-cakephp-plain:before {
  content: "\E940";
}
.devicon-stylus-original:before {
  content: "\E93E";
}
.devicon-express-original-wordmark:before {
  content: "\E93C";
}
.devicon-express-original:before {
  content: "\E93D";
}
.devicon-devicon-plain-wordmark:before {
  content: "\E93A";
}
.devicon-devicon-plain:before {
  content: "\E93B";
}
.devicon-intellij-plain-wordmark:before {
  content: "\E932";
}
.devicon-intellij-plain:before {
  content: "\E933";
}
.devicon-pycharm-plain-wordmark:before {
  content: "\E934";
}
.devicon-pycharm-plain:before {
  content: "\E935";
}
.devicon-rubymine-plain-wordmark:before {
  content: "\E936";
}
.devicon-rubymine-plain:before {
  content: "\E937";
}
.devicon-webstorm-plain-wordmark:before {
  content: "\E938";
}
.devicon-webstorm-plain:before {
  content: "\E939";
}
.devicon-tomcat-line-wordmark:before {
  content: "\E92C";
}
.devicon-tomcat-line:before {
  content: "\E92D";
}
.devicon-vuejs-line-wordmark:before {
  content: "\E92E";
}
.devicon-vuejs-line:before {
  content: "\E92F";
}
.devicon-vuejs-plain-wordmark:before {
  content: "\E930";
}
.devicon-vuejs-plain:before {
  content: "\E931";
}
.devicon-swift-plain-wordmark:before {
  content: "\E92A";
}
.devicon-swift-plain:before {
  content: "\E92B";
}
.devicon-webpack-plain-wordmark:before {
  content: "\E928";
}
.devicon-webpack-plain:before {
  content: "\E929";
}
.devicon-visualstudio-plain-wordmark:before {
  content: "\E926";
}
.devicon-visualstudio-plain:before {
  content: "\E927";
}
.devicon-slack-plain-wordmark:before {
  content: "\E924";
}
.devicon-slack-plain:before {
  content: "\E925";
}
.devicon-facebook-original:before,
.devicon-facebook-plain:before {
	content: "\E91C";
}
.devicon-typescript-original:before,
.devicon-typescript-plain:before {
  content: "\E920";
}
.devicon-babel-original:before,
.devicon-babel-plain:before {
  content: "\E921";
}
.devicon-mocha-plain:before {
  content: "\E919";
}
.devicon-jasmine-plain-wordmark:before {
  content: "\E91B";
}
.devicon-jasmine-plain:before {
  content: "\E91A";
}
.devicon-gatling-plain-wordmark:before {
	content: "\E918";
}
.devicon-gatling-plain:before {
	content: "\E917";
}
.devicon-ssh-original-wordmark:before,
.devicon-ssh-plain-wordmark:before {
	content: "\E916";
}
.devicon-ssh-original:before,
.devicon-ssh-plain:before {
	content: "\E915";
}
.devicon-sourcetree-original-wordmark:before,
.devicon-sourcetree-plain-wordmark:before {
	content: "\E914";
}
.devicon-sourcetree-original:before,
.devicon-sourcetree-plain:before {
	content: "\E913";
}
.devicon-phpstorm-plain-wordmark:before {
	content: "\E912";
}
.devicon-phpstorm-plain:before {
	content: "\E911";
}
.devicon-protractor-plain-wordmark:before {
	content: "\E901";
}
.devicon-protractor-plain:before {
	content: "\E900";
}
.devicon-gradle-plain-wordmark:before {
	content: "\E8F1";
}
.devicon-gradle-plain:before {
	content: "\E902";
}
.devicon-cucumber-plain-wordmark:before {
	content: "\E905";
}
.devicon-cucumber-plain:before {
	content: "\E904";
}
.devicon-jeet-plain-wordmark:before {
	content: "\E906";
}
.devicon-jeet-plain:before {
	content: "\E903";
}
.devicon-gitlab-plain-wordmark:before {
	content: "\E908";
}
.devicon-gitlab-plain:before {
	content: "\E907";
}
.devicon-github-original-wordmark:before,
.devicon-github-plain-wordmark:before {
	content: "\E90A";
}
.devicon-github-original:before,
.devicon-github-plain:before {
	content: "\E909";
}
.devicon-d3js-plain:before {
	content: "\E90C";
}
.devicon-confluence-original-wordmark:before,
.devicon-confluence-plain-wordmark:before {
	content: "\E90E";
}
.devicon-confluence-original:before,
.devicon-confluence-plain:before {
	content: "\E90D";
}
.devicon-bitbucket-original-wordmark:before,
.devicon-bitbucket-plain-wordmark:before {
	content: "\E910";
}
.devicon-bitbucket-original:before,
.devicon-bitbucket-plain:before {
	content: "\E90F";
}
.devicon-safari-line-wordmark:before {
	content: "\E632";
}
.devicon-safari-line:before {
	content: "\E63A";
}
.devicon-safari-plain-wordmark:before {
	content: "\E63B";
}
.devicon-safari-plain:before {
	content: "\E63C";
}
.devicon-jetbrains-plain:before,
.devicon-jetbrains-line:before,
.devicon-jetbrains-line-wordmark:before,
.devicon-jetbrains-plain-wordmark:before {
	content: "\E63D";
}
.devicon-django-line:before,
.devicon-django-line-wordmark:before {
	content: "\E63E";
}
.devicon-django-plain:before,
.devicon-django-plain-wordmark:before {
	content: "\E63F";
}

.devicon-gimp-plain:before {
	content: "\E633";
}

.devicon-redhat-plain-wordmark:before {
	content: "\E62A";
}

.devicon-redhat-plain:before {
	content: "\E62B";
}

.devicon-cplusplus-line:before,
.devicon-cplusplus-line-wordmark:before {
	content: "\E634";
}

.devicon-cplusplus-plain:before,
.devicon-cplusplus-plain-wordmark:before {
	content: "\E635";
}

.devicon-csharp-line:before,
.devicon-csharp-line-wordmark:before {
	content: "\E636";
}

.devicon-csharp-plain:before,
.devicon-csharp-plain-wordmark:before {
	content: "\E637";
}

.devicon-c-line:before,
.devicon-c-line-wordmark:before {
	content: "\E638";
}

.devicon-c-plain:before,
.devicon-c-plain-wordmark:before {
	content: "\E639";
}

.devicon-nodewebkit-line-wordmark:before {
	content: "\E611";
}

.devicon-nodewebkit-line:before {
	content: "\E612";
}

.devicon-nodewebkit-plain-wordmark:before {
	content: "\E613";
}

.devicon-nodewebkit-plain:before {
	content: "\E614";
}

.devicon-nginx-original:before,
.devicon-nginx-original-wordmark:before,
.devicon-nginx-plain:before,
.devicon-nginx-plain-wordmark:before {
	content: "\E615";
}

.devicon-erlang-plain-wordmark:before {
	content: "\E616";
}

.devicon-erlang-plain:before {
	content: "\E617";
}

.devicon-doctrine-line-wordmark:before {
	content: "\E618";
}

.devicon-doctrine-line:before {
	content: "\E619";
}

.devicon-doctrine-plain-wordmark:before {
	content: "\E61A";
}

.devicon-doctrine-plain:before {
	content: "\E625";
}

.devicon-apache-line-wordmark:before {
	content: "\E626";
}

.devicon-apache-line:before {
	content: "\E627";
}

.devicon-apache-plain-wordmark:before {
	content: "\E628";
}

.devicon-apache-plain:before {
	content: "\E629";
}

.devicon-go-line:before {
	content: "\E610";
}

.devicon-redis-plain-wordmark:before {
	content: "\E606";
}

.devicon-redis-plain:before {
	content: "\E607";
}

.devicon-meteor-plain-wordmark:before {
	content: "\E608";
}

.devicon-meteor-plain:before {
	content: "\E609";
}

.devicon-heroku-line-wordmark:before,
.devicon-heroku-original-wordmark:before {
	content: "\E60A";
}

.devicon-heroku-line:before,
.devicon-heroku-original:before {
	content: "\E60B";
}

.devicon-heroku-plain-wordmark:before {
	content: "\E60C";
}

.devicon-heroku-plain:before {
	content: "\E60F";
}

.devicon-go-plain:before {
	content: "\E61B";
}

.devicon-docker-plain-wordmark:before {
	content: "\E61E";
}

.devicon-docker-plain:before {
	content: "\E61F";
}

.devicon-amazonwebservices-original:before,
.devicon-amazonwebservices-plain:before {
	content: "\E603";
}

.devicon-amazonwebservices-plain-wordmark:before {
	content: "\E604";
}

.devicon-android-plain-wordmark:before {
	content: "\E60D";
}

.devicon-android-plain:before {
	content: "\E60E";
}

.devicon-angularjs-plain-wordmark:before {
	content: "\E61C";
}

.devicon-angularjs-plain:before {
	content: "\E61D";
}

.devicon-appcelerator-original:before,
.devicon-appcelerator-plain:before {
	content: "\E620";
}

.devicon-appcelerator-plain-wordmark:before {
	content: "\E621";
}

.devicon-apple-original:before,
.devicon-apple-plain:before {
	content: "\E622";
}

.devicon-atom-original-wordmark:before,
.devicon-atom-plain-wordmark:before {
	content: "\E623";
}

.devicon-atom-original:before,
.devicon-atom-plain:before {
	content: "\E624";
}

.devicon-backbonejs-plain-wordmark:before {
	content: "\E62C";
}

.devicon-backbonejs-plain:before {
	content: "\E62D";
}

.devicon-bootstrap-plain-wordmark:before {
	content: "\E62E";
}

.devicon-bootstrap-plain:before {
	content: "\E62F";
}

.devicon-bower-line-wordmark:before {
	content: "\E630";
}

.devicon-bower-line:before {
	content: "\E631";
}

.devicon-bower-plain-wordmark:before {
	content: "\E64E";
}

.devicon-bower-plain:before {
	content: "\E64F";
}

.devicon-chrome-plain-wordmark:before {
	content: "\E665";
}

.devicon-chrome-plain:before {
	content: "\E666";
}

.devicon-codeigniter-plain-wordmark:before {
	content: "\E667";
}

.devicon-codeigniter-plain:before {
	content: "\E668";
}

.devicon-coffeescript-original-wordmark:before,
.devicon-coffeescript-plain-wordmark:before {
	content: "\E669";
}

.devicon-coffeescript-original:before,
.devicon-coffeescript-plain:before {
	content: "\E66A";
}

.devicon-css3-plain-wordmark:before {
	content: "\E678";
}

.devicon-css3-plain:before {
	content: "\E679";
}

.devicon-debian-plain-wordmark:before {
	content: "\E67E";
}

.devicon-debian-plain:before {
	content: "\E67F";
}

.devicon-dot-net-plain-wordmark:before {
	content: "\E6D3";
}

.devicon-dot-net-plain:before {
	content: "\E6D4";
}

.devicon-drupal-plain-wordmark:before {
	content: "\E6E2";
}

.devicon-drupal-plain:before {
	content: "\E6E3";
}

.devicon-firefox-plain-wordmark:before {
	content: "\E75D";
}

.devicon-firefox-plain:before {
	content: "\E75E";
}

.devicon-foundation-plain-wordmark:before {
	content: "\E7A2";
}

.devicon-foundation-plain:before {
	content: "\E7A3";
}

.devicon-git-plain-wordmark:before {
	content: "\E7A7";
}

.devicon-git-plain:before {
	content: "\E7A8";
}

.devicon-google-original-wordmark:before,
.devicon-google-plain-wordmark:before {
	content: "\E91D";
}

.devicon-google-original:before,
.devicon-google-plain:before {
	content: "\E91E";
}

.devicon-grunt-line-wordmark:before {
	content: "\E7A9";
}

.devicon-grunt-line:before {
	content: "\E7AA";
}

.devicon-grunt-plain-wordmark:before {
	content: "\E7EA";
}

.devicon-grunt-plain:before {
	content: "\E7EB";
}

.devicon-gulp-plain:before {
	content: "\E7EC";
}

.devicon-html5-plain-wordmark:before {
	content: "\E7F6";
}

.devicon-html5-plain:before {
	content: "\E7F7";
}

.devicon-ie10-original:before,
.devicon-ie10-plain:before {
	content: "\E7F8";
}

.devicon-illustrator-line:before {
	content: "\E7F9";
}

.devicon-illustrator-plain:before {
	content: "\E7FA";
}

.devicon-inkscape-plain-wordmark:before {
	content: "\E834";
}

.devicon-inkscape-plain:before {
	content: "\E835";
}

.devicon-java-plain-wordmark:before {
	content: "\E841";
}

.devicon-java-plain:before {
	content: "\E842";
}

.devicon-javascript-plain:before {
	content: "\E845";
}

.devicon-jquery-plain-wordmark:before {
	content: "\E849";
}

.devicon-jquery-plain:before {
	content: "\E84A";
}

.devicon-krakenjs-plain-wordmark:before {
	content: "\E84F";
}

.devicon-krakenjs-plain:before {
	content: "\E850";
}

.devicon-laravel-plain-wordmark:before {
	content: "\E851";
}

.devicon-laravel-plain:before {
	content: "\E852";
}

.devicon-less-plain-wordmark:before {
	content: "\E853";
}

.devicon-linux-plain:before {
	content: "\EB1C";
}

.devicon-mongodb-plain-wordmark:before {
	content: "\EB43";
}

.devicon-mongodb-plain:before {
	content: "\EB44";
}

.devicon-moodle-plain-wordmark:before {
	content: "\EB5A";
}

.devicon-moodle-plain:before {
	content: "\EB5B";
}

.devicon-mysql-plain-wordmark:before {
	content: "\EB60";
}

.devicon-mysql-plain:before {
	content: "\EB61";
}

.devicon-nodejs-plain-wordmark:before {
	content: "\EB69";
}

.devicon-nodejs-plain:before {
	content: "\EB6A";
}

.devicon-oracle-original:before,
.devicon-oracle-plain:before {
	content: "\EB6B";
}

.devicon-photoshop-line:before {
	content: "\EB6C";
}

.devicon-photoshop-plain:before {
	content: "\EB6D";
}

.devicon-php-plain:before {
	content: "\EB71";
}

.devicon-postgresql-plain-wordmark:before {
	content: "\EB7C";
}

.devicon-postgresql-plain:before {
	content: "\EB7D";
}

.devicon-python-plain-wordmark:before {
	content: "\EB88";
}

.devicon-python-plain:before {
	content: "\EB89";
}

.devicon-rails-plain-wordmark:before {
	content: "\EBA2";
}

.devicon-rails-plain:before {
	content: "\EBA3";
}

.devicon-react-original-wordmark:before,
.devicon-react-plain-wordmark:before {
	content: "\E600";
}

.devicon-react-original:before,
.devicon-react-plain:before {
	content: "\E601";
}

.devicon-ruby-plain-wordmark:before {
	content: "\EBC9";
}

.devicon-ruby-plain:before {
	content: "\EBCA";
}

.devicon-sass-original:before,
.devicon-sass-plain:before {
	content: "\EBCB";
}

.devicon-sequelize-original-wordmark:before,
.devicon-sequelize-plain-wordmark:before {
	content: "\E922";
}

.devicon-sequelize-original:before,
.devicon-sequelize-plain:before {
	content: "\E923";
}

.devicon-symfony-original-wordmark:before,
.devicon-symfony-plain-wordmark:before {
	content: "\E602";
}

.devicon-symfony-original:before,
.devicon-symfony-plain:before {
	content: "\E605";
}

.devicon-travis-plain-wordmark:before {
	content: "\EBCC";
}

.devicon-travis-plain:before {
	content: "\EBCD";
}

.devicon-trello-plain-wordmark:before {
	content: "\EBCE";
}

.devicon-trello-plain:before {
	content: "\EBCF";
}

.devicon-twitter-original:before,
.devicon-twitter-plain:before {
	content: "\E91F";
}

.devicon-ubuntu-plain-wordmark:before {
	content: "\EBD0";
}

.devicon-ubuntu-plain:before {
	content: "\EBD1";
}

.devicon-vim-plain:before {
	content: "\EBF3";
}

.devicon-windows8-original-wordmark:before,
.devicon-windows8-plain-wordmark:before {
	content: "\EBF4";
}

.devicon-windows8-original:before,
.devicon-windows8-plain:before {
	content: "\EBF5";
}

.devicon-wordpress-plain-wordmark:before {
	content: "\EBFD";
}

.devicon-wordpress-plain:before {
	content: "\EBFE";
}

.devicon-yii-plain-wordmark:before {
	content: "\EC01";
}

.devicon-yii-plain:before {
	content: "\EC02";
}

.devicon-zend-plain-wordmark:before {
	content: "\EC03";
}

.devicon-zend-plain:before {
	content: "\EC04";
}

